@layer packages {
    .inputWrapperBase {
        --wrapper-field-state-color: var(--colors-gray-600);

        display: flex;
        transition:
            border-color var(--transition-timingFunction) var(--transition-duration),
            box-shadow var(--transition-timingFunction) var(--transition-duration);
        border-radius: calc(1 * var(--globals-baseUnit));
        border: 1px solid var(--wrapper-field-state-color);
        background: #fff;

        &:has(input:-webkit-autofill) {
            padding: 0;

            & input {
                padding: calc(1.5 * var(--globals-baseUnit)) calc(2 * var(--globals-baseUnit));
                border-radius: var(--globals-baseUnit);
            }
        }
    }

    .borderlessStyle {
        border: none;
    }

    .inputWrapperBase:hover {
        /*elevation*/
        box-shadow: 0 var(--globals-baseUnit) calc(2.5 * var(--globals-baseUnit)) rgba(174, 177, 180, 0.3);
    }

    .inputWrapperPadding {
        padding: 1.2rem calc(2 * var(--globals-baseUnit));
    }

    .inputWrapperDisabled {
        background: var(--colors-gray-100);
    }

    .inputWrapStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 auto;
    }

    .leftElementStyle {
        margin-right: calc(1 * var(--globals-baseUnit));
        display: flex;
        align-items: center;
    }

    .rightElementStyle {
        margin-left: calc(1 * var(--globals-baseUnit));
        display: flex;
        align-items: center;
    }

    .detailStyle {
        margin-top: calc(0.5 * var(--globals-baseUnit));
        color: var(--colors-gray-700);
    }
}
@layer packages {
    .inputWrapperBase {
        --wrapper-field-state-color: var(--colors-gray-600);

        display: flex;
        transition:
            border-color var(--transition-timingFunction) var(--transition-duration),
            box-shadow var(--transition-timingFunction) var(--transition-duration);
        border-radius: calc(1 * var(--globals-baseUnit));
        border: 1px solid var(--wrapper-field-state-color);
        background: #fff;

        &:has(input:-webkit-autofill) {
            padding: 0;

            & input {
                padding: calc(1.5 * var(--globals-baseUnit)) calc(2 * var(--globals-baseUnit));
                border-radius: var(--globals-baseUnit);
            }
        }
    }

    .borderlessStyle {
        border: none;
    }

    .inputWrapperBase:hover {
        /*elevation*/
        box-shadow: 0 var(--globals-baseUnit) calc(2.5 * var(--globals-baseUnit)) rgba(174, 177, 180, 0.3);
    }

    .inputWrapperPadding {
        padding: 1.2rem calc(2 * var(--globals-baseUnit));
    }

    .inputWrapperDisabled {
        background: var(--colors-gray-100);
    }

    .inputWrapStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 auto;
    }

    .leftElementStyle {
        margin-right: calc(1 * var(--globals-baseUnit));
        display: flex;
        align-items: center;
    }

    .rightElementStyle {
        margin-left: calc(1 * var(--globals-baseUnit));
        display: flex;
        align-items: center;
    }

    .detailStyle {
        margin-top: calc(0.5 * var(--globals-baseUnit));
        color: var(--colors-gray-700);
    }
}
